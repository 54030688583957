import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { HashLoader } from 'react-spinners';
import { theme } from '../../../tailwind.config';
import AppPageContainer from '../../components/AppPageContainer';
import Logo from '../../images/Logo_Primary.svg';
import { registerUser } from '../../modules/auth_module';

const LoginPage = () => {

    const [registerState, setRegisterState] = useState("INIT");
    const [userForm, setUserForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        organizationName: '',
    })
    const onUserFormChage = (e, field) => {
        setUserForm({...userForm, [field]: e.target.value})
    }

    const onRegister = async () => {
        setRegisterState("LOADING")
        try {
            const res = await registerUser(userForm)
            console.log(res)
            if (res) {
                setRegisterState("REGISTERED")
                navigate('/app/login?toast=registration')
            } else {
                setRegisterState("ERROR")
            }
        } catch {
            setRegisterState("ERROR")

        }
     
    }

    return (
            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="lg:col-start-2 lg:col-end-2 mt-8 mb-5 flex justify-center">
                    <img src={Logo} alt="Kodama" />
                </div>
                
                { registerState !== 'REGISTERED' && (
                    <div className="lg:col-start-2 lg:col-end-2 shadow-lg rounded-xl p-5  border border-gray-200">
                        { registerState === 'ERROR' && (
                            <div className="mb-4 text-center">
                                <h2 className="text-xl font-bold text-error">Something bad happened</h2>
                                <p className="">Maybe this account already exists?</p>
                                <p className=""><Link className="text-primary underline" to="/app/login">Click Here</Link> to login</p>
                            </div>
                        )}
                        {registerState === 'LOADING' && (
                            <div className="h-72 flex items-center justify-center">
                                <HashLoader color={theme.extend.colors.primary.DEFAULT}/>
                            </div>
                        )}
                        {registerState !== 'LOADING' && (
                        <>
                            <div>
                                <h2 className="text-xl font-bold">Sign Up</h2>
                                <p className="text-sm">Welcome to Kodama</p>
                            </div>
                            <div className="mt-4">
                                <label for="firstName"
                                    className="text-sm"
                                >
                                    First Name
                                </label>
                                <input 
                                    name="firstName" 
                                    value={userForm.firstName}
                                    onChange={(e) => onUserFormChage(e, 'firstName')}
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                ></input>
                            </div>
                            <div className="mt-1">
                                <label for="lastName" className="text-sm">Last Name</label>
                                <input 
                                    name="lastName" 
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                    value={userForm.lastName}
                                    onChange={(e) => onUserFormChage(e, 'lastName')}
                                ></input>
                            </div>
                            
                            <div className="mt-1">
                                <label for="email" className="text-sm">Email</label>
                                <input 
                                    name="email" 
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                    value={userForm.email}
                                    onChange={(e) => onUserFormChage(e, 'email')}
                                ></input>
                            </div>
                            <div className="mt-1">
                                <label for="organizationName" className="text-sm">Organization Name</label>
                                <input 
                                    name="organizationName" 
                                    value={userForm.organizationName}
                                    onChange={(e) => onUserFormChage(e, 'organizationName')}
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                ></input>
                            </div>
                            <div className="mt-1">
                                <label htmlFor="password" className="text-sm">Password</label>
                                <input 
                                    name="password" 
                                    type="password"
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                    value={userForm.password}
                                    onChange={(e) => onUserFormChage(e, 'password')}
                                ></input>
                            </div>
                            <div className="mt-3 flex w-full justify-end">
                                <div className="w-full">
                                    <button onClick={onRegister} className="py-2 px-5 w-full rounded bg-primary text-white">Register</button>
                                </div>
                            </div>
                            <div className="text-sm mt-5">
                                Already have an account? <Link to="/app/login" className="text-secondary underline cursor-pointer">Log in now.</Link>
                            </div>
                        </>
                        )}
                    </div>
                )}
            </div>
    )
};

export default LoginPage;
